import React from "react";
import {Link} from "gatsby";
import MyPortableText from "./MyPortableText";

const Job = ({job, info}) => {

    return (
        <div className={"einzeljob__outer"}>
            <div className="einzeljob__inner">
                <h2>{job.title}</h2>
                <MyPortableText value={job._rawDescription}></MyPortableText>
                <Link to={job.slug.current} className="btn bg-orange-dark color-light uppercase hover-moveup">
                    {info}
                </Link>
            </div>
        </div>
    )
}

export default Job;
